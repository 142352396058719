import React, { useEffect, useState } from "react";
import EmbedHeader from "../../../Header/EmbedHeader";
import Footer from "../../../Footer/Footer";
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import { useDispatch } from "react-redux";
import { unmountCreateEmbed } from "../../../../Redux/Actions/EmbedActions";
import { onFetchFont } from "../../../../Redux/Actions/CommonActions";
import GeneralSetting from "./GeneralSetting";
import Customization from "./Customization";

const CreateEmbed = () => {
    const dispatch = useDispatch();
    const [font, setFont] = useState([]);
    const [tabs, setTabs] = useState({
        first: true,
        second: false,
    });

    const handleActiveTab = (type) => {
        if (type === "first") {
            setTabs({
                first: true,
                second: false,
            });
        }
        if (type === "second") {
            setTabs({
                first: false,
                second: true,
            });
        }
    };

    const fetchFonts = () => {
        dispatch(onFetchFont(setFont, false, true));
    };

    useEffect(() => {
        fetchFonts();

        return () => {
            dispatch(unmountCreateEmbed());
        };
    }, []);

    return (
        <>
            <EmbedHeader />
            <div className="embed-progress">
                <div className="embed-progress-in"></div>
            </div>
            <div className="site-wrapper">
                <div className="site-container" style={{ paddingBottom: "100px" }}>
                    <div className="embed-wrap width-600">
                        <div className="titleBar">
                            <div className="titleBar-left">
                                <h2>Create New Embed Code</h2>
                                <p>You can customize the working time as well</p>
                            </div>
                        </div>

                        <div>
                            <Tab.Container id="left-tabs-example" activeKey={tabs.first ? "first" : "second"}>
                                <Nav variant="b" className="navStyle">
                                    <Nav.Item>
                                        <Nav.Link onClick={() => handleActiveTab("first")} className={tabs.first ? 'active' : ""}>
                                            <h6>General Settings</h6>
                                            <p>Write Basic Information</p>
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link className={tabs.second ? 'active' : ""}>
                                            <h6>Customization</h6>
                                            <p>Upload your document</p>
                                        </Nav.Link>
                                    </Nav.Item>
                                </Nav>
                                <Tab.Content className="pt-5">
                                    <Tab.Pane className={`${tabs.first ? "active show" : ""}`}>
                                        <GeneralSetting handleActiveTab={handleActiveTab} />
                                    </Tab.Pane>
                                    <Tab.Pane className={`${tabs.second ? "active show" : ""}`}>
                                        <Customization font={font} />
                                    </Tab.Pane>
                                </Tab.Content>
                            </Tab.Container>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    );
};
export default CreateEmbed;
































