import React, { useState } from "react";
import Header from "../../../Header/Header";
import Footer from "../../../Footer/Footer";
import ProfileSidebar from "../ProfileSidebar";
import TitleBar from "../../../CommonComponents/TitleBar";
import IntegrationAcc from "./IntegrationAcc";
import { useSelector } from "react-redux";

const Integrations = () => {
    const socials = useSelector(state => state.social)
    return (
        <>
            <TitleBar title="Integrations" />
            <Header />
            <div className="site-wrapper">
                <div className="site-container">
                    <div className="account-wrap">
                        <ProfileSidebar />
                        <div className="account-right">
                            <div className="titleBar">
                                <div className="titleBar-left">
                                    <h2>Integrations</h2>
                                    <p>You can change and manage account details</p>
                                </div>
                            </div>
                            <div className="dashboard-block">
                                <div className="integrations">
                                    <IntegrationAcc
                                        name="Google Calendar"
                                        desc="Booking your events in Google Calendar"
                                        image={require('../../../../assets/images/icon/google.png')}
                                        type="GC"
                                        data={socials.google}
                                    />

                                    <IntegrationAcc
                                        name="Facebook"
                                        type="FB"
                                        desc="Post contents in Facebook"
                                        image={require('../../../../assets/images/icon/facebook-logo.svg').default}
                                        data={socials.facebook}
                                    />

                                    <IntegrationAcc
                                        name="Youtube"
                                        desc="Post contents in Youtube"
                                        type="YT"
                                        image={require('../../../../assets/images/youtube.png')}
                                        data={socials.instagram}
                                    />

                                    <IntegrationAcc
                                        name="Cal.com"
                                        type="CC"
                                        url="calcom"
                                        desc="Book your events in Cal.com Calendar"
                                        image={require('../../../../assets/images/icon/cal-com.svg').default}
                                        data={socials.cal}
                                    />

                                    <IntegrationAcc
                                        name="ElevenLabs"
                                        desc="Give a unique voice and clone your own voice "
                                        type="EL"
                                        url="create-elevenlabs"
                                        image={require('../../../../assets/images/icon/elevenlabs.png')}
                                        data={socials.elevenlabs}
                                    />

                                    {/* <IntegrationAcc
                                        name="Instagram"
                                        desc="Messaging to teams"
                                        type="IG"
                                        image={require('../../../../assets/images/icon/instagram.svg').default}
                                        data={socials.instagram}
                                    /> */}

                                    <IntegrationAcc
                                        name="Autoresponder"
                                        desc="Connect your Autoresponder to save leads"
                                        type="AR"
                                        url="create-autoresponder"
                                        image={require('../../../../assets/images/icon/autoresponder.png')}
                                        data={socials.autoresponder}
                                    />

                                    <IntegrationAcc
                                        name="SMTP"
                                        desc="Connect SMTP for sending emails"
                                        type="smtp"
                                        url="smtp-add"
                                        image={require('../../../../assets/images/icon/smtp.svg').default}
                                        data={socials.smtp}
                                    />

                                    <IntegrationAcc
                                        name="Twilio"
                                        desc="Connect Twilio for sending SMS"
                                        type="twilio"
                                        url="connect-twilio"
                                        image={require('../../../../assets/images/icon/twilio.svg').default}
                                        data={socials.twilio}
                                    />

                                    <IntegrationAcc
                                        name="Paypal"
                                        desc="Connect Paypal to rent your staffs"
                                        type="paypal"
                                        url="paypal"
                                        image={require('../../../../assets/images/paypal.svg').default}
                                        data={socials.paypal}
                                    />

                                    <IntegrationAcc
                                        name="Stripe"
                                        desc="Connect Stripe to rent your staffs"
                                        type="stripe"
                                        url="stripe-connect"
                                        image={require('../../../../assets/images/stripe.svg').default}
                                        data={socials.stripe}
                                    />

                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </div>

            <Footer></Footer>
        </>
    )
}

export default Integrations;