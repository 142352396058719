// import React from "react";
// import GoogleLogin from "react-google-login";
// import { useDispatch, useSelector } from "react-redux";
// import { saveYoutube } from "../../../Redux/Actions/SocialActions";

// const Youtube = ({checkAccount, editClient}) => {

//     const auth = useSelector(state => state.auth);
//     const dispatch = useDispatch();

//     const responseGoogle = (response) => {
        // if (response.code) {
        //     const data = {
        //         user_id: auth.user.id,
        //         code: response.code,
        //         clientId: editClient.id
        //     };
        //     dispatch(saveYoutube(data));
        // }
//     }

//     return (
//         <>
//             <GoogleLogin
//                 clientId="86528469601-ndaebdjq239lrnhsv9tuc91mvnarc8q9.apps.googleusercontent.com"
//                 render={(renderProps) => (
//                     <span style={{ cursor: "pointer" }} className={`${checkAccount === false ? 'hide-button' : ''}`} onClick={renderProps.onClick}>
//                             <a className='site-link sm' > <span> Connect</span> </a>
//                     </span>
//                 )}
//                 onSuccess={responseGoogle}
//                 onFailure={responseGoogle}
//                 scope="https://www.googleapis.com/auth/youtube"
//                 responseType="code"
//                 accessType="offline"
//                 prompt="consent"
//                 cookiePolicy={"single_host_origin"}
//             />
//         </>
//     )

// }


// export default Youtube;



import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { saveYoutube } from '../../../../Redux/Actions/SocialActions';

const Youtube = ({ checkAccount, editClient }) => {
  const dispatch = useDispatch();
  const auth = useSelector(state => state.auth);
  const codeClientRef = useRef(null);

  const CLIENT_ID = '734005313991-78389b4ahtc7oqv7u50472akavogoeqt.apps.googleusercontent.com';
  const REDIRECT_URI = 'https://backend.aipodcasts.io/save-youtube'; 
  const SCOPES = 'https://www.googleapis.com/auth/youtube.upload https://www.googleapis.com/auth/youtube';

  useEffect(() => {
    const initGSI = () => {
      if (window.google && !codeClientRef.current) {
        codeClientRef.current = window.google.accounts.oauth2.initCodeClient({
          client_id: CLIENT_ID,
          scope: SCOPES,
          ux_mode: 'popup',
          redirect_uri: REDIRECT_URI,
          state: auth?.user?.id,
          callback: (response) => {
            if (response.code) {
              const data = {
                user_id: auth.user.id,
                code: response.code,
              };
              dispatch(saveYoutube(data));
            } else {
              console.error("No code received from Google");
              alert("Authorization failed or cancelled.");
            }
          }
        });
      }
    };

    const loadGoogleScript = () => {
      const script = document.createElement('script');
      script.src = 'https://accounts.google.com/gsi/client';
      script.async = true;
      script.defer = true;
      script.onload = initGSI;
      document.body.appendChild(script);
    };

    if (!window.google) {
      loadGoogleScript();
    } else {
      initGSI();
    }
  }, [auth, dispatch, editClient]);

  const handleConnect = () => {
    if (codeClientRef.current) {
      codeClientRef.current.requestCode();
    } else {
      alert('Google Sign-In not ready yet. Please try again shortly.');
    }
  };

  return (
    <span style={{ cursor: 'pointer' }} className={`${checkAccount === false ? 'hide-button' : ''}`} onClick={handleConnect}>
      <button className='theme-btn sm'><span>Connect</span></button>
    </span>
  );
};

export default Youtube;
