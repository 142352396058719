
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { onChangeEmbed, onChangePaymentEmbed } from '../../../../Redux/Actions/EmbedActions'
import { onUploadMedia } from '../../../../Redux/Actions/CommonActions'
import { FaSpinner } from 'react-icons/fa'
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom'

const GeneralSetting = ({ handleActiveTab }) => {
    const dispatch = useDispatch()
    const createEmbed = useSelector(state => state.embed.create)
    const autoresponder = useSelector(state => state.social.autoresponder)
    const stripe = useSelector(state => state.social.stripe)
    const navigate = useNavigate()
    const [errors, setErrors] = useState({ url: '' })
    const [percent, setPercent] = useState(0)
    const [loader, setLoader] = useState({
        upload: false,
    })

    const handleSubmit = (e) => {
        e.preventDefault()
        if (!isValidUrl(createEmbed.url)) {
            setErrors((prevErrors) => ({ ...prevErrors, url: 'Please enter a valid URL starting with http:// or https://' }));
            return handleActiveTab("second");
        }

        if (createEmbed.name === '') {
            setErrors((prevErrors) => ({ ...prevErrors, name: "Please enter a name" }));
            return;
        }
        if (createEmbed.isPayment === 0) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Please enable the payment option to proceed',
                confirmButtonColor: "#000"
            })
            return
        }


        handleActiveTab("second")
    }

    const isValidUrl = (url) => {
        const regex = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,6}(\/[a-zA-Z0-9#]+\/?)*$/;
        return regex.test(url)
    }

    const handleStripeConnect = () => {
        navigate('/integrations')
    }

    const handleChange = (e) => {
        let { name, value, checked } = e.target
        if (name === "isPayment") {
            value = checked ? 1 : 0
        }
        dispatch(onChangeEmbed(name, value))

        if (name === "url") {
            setErrors({ ...errors, url: '' })
        }
    }

    const handlePaymentChange = (e) => {
        let { name, value } = e.target

        if (value < 0) {
            value = '';
        }
        dispatch(onChangePaymentEmbed(name, value))
    }

    const onCompeleteUpload = (url) => {
        dispatch(onChangeEmbed("image", url))
    }
    const onInputImage = (e) => {
        if (e.target.files.length > 0) {
            const formData = new FormData()
            formData.append("file", e.target.files[0])
            if (['image/png', 'image/jpg', 'image/jpeg'].includes(e.target.files[0].type.toLowerCase())) {
                if (e.target.files[0].size < 5000000) {
                    formData.append("upload_type", "images")
                    setLoader({
                        ...loader,
                        upload: true
                    })
                    dispatch(onUploadMedia(formData, onCompeleteUpload, loader, setLoader, setPercent, "image"))
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Max allowed size is 5MB!',
                        confirmButtonColor: "#000"
                    })
                }
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'You have selected invalid file type!',
                    confirmButtonColor: "#000"
                })
            }
        }
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className="input-wrap mt-0">
                <label htmlFor="">Name</label>
                <div className="inp-outer">
                    <input
                        className="inp"
                        type="text"
                        placeholder="Enter Embed Name"
                        value={createEmbed.name}
                        name="name"
                        onChange={handleChange}
                        required={true}
                    />
                    {errors.name && <span className="error-text">{errors.name}</span>}
                </div>
            </div>
            <div className="input-wrap">
                <label htmlFor="">Website URL</label>
                <div className="inp-outer">
                    <input
                        className="inp"
                        type="url"
                        placeholder="Enter website URL"
                        value={createEmbed.url}
                        name="url"
                        onChange={handleChange}
                        required
                    />
                    {errors.name && <span className="error-text">{errors.url}</span>}
                </div>
            </div>
            <div className="input-wrap">
                <label htmlFor="">Autoresponder</label>
                <div className="inp-outer">
                    <select
                        className="inp"
                        value={createEmbed.autoresponderId}
                        name="autoresponderId"
                        onChange={handleChange}
                    >
                        <option value="">Select Autoresponder</option>
                        {autoresponder.length > 0 ?
                            autoresponder.map((curElem, index) => (
                                <option key={index} value={curElem.id}>{curElem.name}</option>
                            )) : ""}
                    </select>
                </div>
            </div>
            <div className="input-wrap">
                <div className="file-field">
                    <div className="me-3 d-flex align-items-center justify-content-center flImg" style={{ height: 50 }}>
                        {loader.upload ?
                            <FaSpinner className="spin" size={25} />
                            :
                            <img alt="" src={createEmbed.image} style={{ maxHeight: "100%" }} />
                        }
                    </div>
                    <div className='text-center'>
                        <h6>Click to Upload Embed Image</h6>
                        <p>JPG, PNG, JPEG up to 5MB is allowed <br /> (Maximum pixels allowed 1000 x 1000 px)</p>
                    </div>
                    {loader.upload ? null :
                        <input
                            type="file"
                            onChange={onInputImage}
                            accept="image/jpg, image/png, image/jpeg"
                        />
                    }
                </div>
            </div>

            <div className="titleBar mt-5">
                <div className="titleBar-left">
                    <h2>Enable Payment</h2>
                    <p>Decide if you want to charge your client when renting a staff</p>
                </div>
                <div>
                    <div className="switch-btn">
                        <label className="switch">
                            <input
                                type="checkbox"
                                checked={+createEmbed.isPayment === 0 ? false : true}
                                name="isPayment"
                                onChange={handleChange}
                            />
                            <span className="slider round"></span>
                        </label>
                    </div>
                </div>
            </div>

            {createEmbed.isPayment === 1 ? (
                <>
                    <div className="input-wrap">
                        <label htmlFor="">Payment Plan</label>
                        <div className="inp-outer">
                            <select
                                className="inp"
                                name="plan"
                                value={createEmbed.paymentData.plan}
                                onChange={handlePaymentChange}
                                required={+createEmbed.isPayment === 1 ? true : false}
                            >
                                <option value="">Select Plan</option>
                                <option value="one-time">One-time</option>
                                <option value="quarterly">Quarterly</option>
                                <option value="monthly">Monthly</option>
                                <option value="annually">Annually</option>
                            </select>
                        </div>

                        {stripe.length > 0 ? (
                            <>
                                <div className="input-wrap">
                                    <label htmlFor="">Collect payments (Stripe)</label>
                                    <div className="inp-outer">
                                        <select
                                            className="inp"
                                            value={createEmbed.stripe}
                                            name="autoresponderId"
                                            onChange={handleChange}
                                            required={+createEmbed.isPayment === 1 ? true : false}
                                        >
                                            <option value="">Select Account</option>
                                            {stripe.map((curElem, index) => (
                                                <option key={index} value={curElem.id}>{curElem.name}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>

                                <div className="input-wrap">
                                    <label htmlFor="">Pay amount($)</label>
                                    <div className="inp-outer">
                                        <input
                                            className="inp"
                                            type="number"
                                            placeholder="2000"
                                            name="amount"
                                            value={createEmbed.paymentData.amount}
                                            onChange={handlePaymentChange}
                                            min="0"
                                            required={+createEmbed.isPayment === 1 ? true : false}
                                        />
                                    </div>
                                </div>
                            </>
                        ) : (
                            <div className="builder-block">
                                <div className="row flex-row">
                                    <div className="col-12 col-sm-auto icon-box">
                                        <div className="col-auto">
                                            <div className="payment-provider-logo">
                                                <img alt="" src={require('../../../../assets/images/stripe.svg').default} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm py-3 p-sm-0 ">
                                        <h6>Collect payments (Stripe)</h6>
                                        <p className="description">
                                            Connect your Stripe account to collect payments from your customers.
                                        </p>
                                    </div>
                                    <div className="col-12 col-sm-auto">
                                        <button className="theme-btn " onClick={handleStripeConnect}>Connect</button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </>
            ) : ""}

            <button className="theme-btn mt-5 full" type='submit'>
                <span>Next</span>
            </button>
        </form>
    )
}

export default GeneralSetting

