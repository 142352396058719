import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { onChangeStyleEmbed, onCreateEmbed } from '../../../../Redux/Actions/EmbedActions'
import { FaSpinner } from 'react-icons/fa'
import { useLocation, useNavigate } from 'react-router-dom'
import queryString from 'query-string'
import { SketchPicker } from 'react-color'
import rgbHex from "rgb-hex";

const Customization = ({ font}) => {
    const dispatch = useDispatch()
    const location = useLocation()
    const navigate = useNavigate()
    const { hid, sid } = queryString.parse(location.search)
    const createEmbed = useSelector(state => state.embed.create)
    const [colorShow, setColorShow] = useState(false)
    const [loader, setLoader] = useState({
        create: false,
    })

    const handleStyleChange = (e) => {
        let { name, value } = e.target
        dispatch(onChangeStyleEmbed(name, value))
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setLoader({
            ...loader,
            create: true
        })
        let data = { ...createEmbed, hireId: hid, staffId: sid }

        let url = "create-embed"
        dispatch(onCreateEmbed(url, data, navigate, loader, setLoader))
    }

    const onInputColor = (e) => {
        let rgba = `rgba(${e.rgb.r}, ${e.rgb.g}, ${e.rgb.b}, ${e.rgb.a.toFixed(2)})`
        let hex = `#${rgbHex(rgba)}`
        dispatch(onChangeStyleEmbed("brandColor", hex))
    }

    return (
        <form>
            <div className="input-wrap mt-0">
                <label htmlFor="">Theme</label>
                <div className="inp-outer">
                    <select
                        className="inp"
                        value={createEmbed.styleData.theme}
                        name="theme"
                        onChange={handleStyleChange}
                    >
                        <option value="dark">Dark Theme</option>
                        <option value="light">Light Theme</option>
                    </select>
                </div>
            </div>

            <div className="input-wrap">
                <label htmlFor="">Brand Color</label>
                <div className="inp-outer">
                    <div style={{ position: "relative" }}>
                        <input className="inp alt" type="text" value={createEmbed.styleData.brandColor} readOnly />
                        <span style={{
                            width: 50,
                            height: "100%",
                            background: createEmbed.styleData.brandColor,
                            top: 0,
                            right: 0,
                            borderRadius: 5,
                            position: "absolute",
                            border: "1px solid #b3b0b0",
                            cursor: "pointer"
                        }}
                            onClick={() => setColorShow(true)}
                        />
                    </div>
                </div>

                {colorShow ?
                    <>
                        <div className="swatch-cover"
                            onClick={() => setColorShow(false)}
                        />
                        <SketchPicker
                            color={createEmbed.styleData.brandColor} onChange={(e) => onInputColor(e)}
                        />
                    </>
                    : null}

                {/* <input
                        className="inp"
                        type="text" placeholder="#110000ff" /> */}
            </div>

            <div className="input-wrap">
                <label htmlFor="">Font Name</label>
                <div className="inp-outer">
                    <select
                        className="inp"
                        value={createEmbed.styleData.fontName}
                        name="fontName"
                        onChange={handleStyleChange}
                    >
                        <option value="">Select Font</option>
                        {font.length > 0 ?
                            font.map((curElem, index) => {
                                return (
                                    <option key={index} value={curElem}>{curElem}</option>
                                )
                            })
                            : ""}

                    </select>
                </div>
            </div>

            <div className="input-wrap">
                <label htmlFor="">Card Size</label>
                <div className="inp-outer">
                    <select className="inp"
                        value={createEmbed.styleData.cardSize}
                        name="cardSize"
                        onChange={handleStyleChange}
                    >
                        <option value="small">Small</option>
                        <option value="medium">Medium</option>
                        <option value="large">Large</option>
                    </select>
                </div>
            </div>
            <button type="submit" className="theme-btn mt-4 full" onClick={handleSubmit}><span>{loader.create ? <>Creating <FaSpinner className="spin" /> </> : "Create Embed"}</span></button>

        </form>
    )
}

export default Customization



