import React, { useEffect, useState } from "react";
import BackHeader from "../../../Header/BackHeader";
import Footer from "../../../Footer/Footer";
import { BiTrash, BiSolidCopy } from "react-icons/bi"
import { BsCardImage, BsGlobeEuropeAfrica } from "react-icons/bs"
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { onFetchEmbed, onUpdateStatus } from "../../../../Redux/Actions/EmbedActions"

const EmbedCode = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const auth = useSelector(state => state.auth)
  const { id } = queryString.parse(location.search)
  const [ebData, setEbData] = useState(false);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [loader, setLoader] = useState({
    fetch: true
  })

  const headerCode = `<script type="text/Javascript" id="ai_offices_widget" src="https://backend.aioffices.io/front-end/embed.js?uId=${ebData.userId}&embId=${ebData.id}"></script> `
  const bodyCode = `<div id = "aioffices_chat_box"></div >`

  const handleActive = () => {
    const val = +ebData.status ? 0 : 1
    setEbData({
      ...ebData,
      status: val
    })
    let data = {
      id,
      status: val
    }
    dispatch(onUpdateStatus(data, ebData, setEbData))
  }


  const handleCopy = (type) => {
    var copyText;
    if (type === "header") {
      copyText = document.getElementById("headerCode");
      // setEmbed({
      //   ...embed,
      //   headerStatus: true,
      //   checkoutStatus: false,
      // });
    } else {
      copyText = document.getElementById("checkoutCode");
      // setEmbed({
      //   ...embed,
      //   headerStatus: false,
      //   checkoutStatus: true,
      // });
    }
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    navigator.clipboard.writeText(copyText.value);
  }

  const fetchEmbed = () => {
    let data = { id }
    dispatch(onFetchEmbed(data, setEbData, loader, setLoader))
  }

  useEffect(() => {
    if (id) {
      fetchEmbed()
    }
  }, [id])


  return (
    loader.fetch ?
      <div className="loader-sec">
        <div className="loader" />
      </div>
      :
      <>
        <BackHeader />

        <div className="site-wrapper">
          <div className="site-container">
            <div className="embed-wrap">
              <div className="embed-block mt-0">
                <div className="embed-block-top">
                  <div className="embed-user-icon"><BsCardImage /></div>
                  {+auth.user.isClientAccount === 1 ? null :
                    <div className="d-flex align-items-center">
                      <div className="switch-btn">
                        <label htmlFor="" className="switch">
                          <input
                            type="checkbox"
                            checked={+ebData.status}
                          />
                          <span className="slider round" onClick={handleActive}></span>
                        </label>
                      </div>
                      <div className="embed-user-icon ms-2" onClick={handleShow}><BiTrash /></div>
                    </div>}
                </div>

                <h6 className="pt-4">{ebData.name}</h6>
                <div className="embed-meta">
                  <div><span className="embed-meta-txt">{ebData.created}</span></div>
                  <div><span className="embed-meta-img"><BsGlobeEuropeAfrica /></span> <span className="embed-meta-txt">{ebData.url}</span></div>
                  <div><span className="embed-meta-img"><img alt="" src={ebData?.staff[0]?.image} /></span> <span className="embed-meta-txt">{ebData.staff[0].name}</span></div>
                </div>
              </div>

              <div className="embed-block">
                <div className="embed-block-top">
                  <div className="embed-block-top-left">
                    <h2>Header Code</h2>
                    <p>Insert this code in the header section between &#x3C;head&#x3E;&#x3C;/head&#x3E; tags in your page</p>
                  </div>
                  <div className="d-flex align-items-center">
                    <div className="embed-user-icon ms-2" onClick={() => handleCopy("header")} style={{ cursor: "pointer" }}><BiSolidCopy /></div>
                  </div>
                </div>
                <div className="code-block p-1">
                  <p>
                    <textarea
                      value={headerCode}
                      id="headerCode"
                      readOnly
                      rows={5}
                      style={{ background: "transparent", border: "none", height: "100%", width: "100%", padding: 10 }}
                    />
                  </p>
                </div>
              </div>

              <div className="embed-block">
                <div className="embed-block-top">
                  <div className="embed-block-top-left">
                    <h2>Body Code</h2>
                    <p>Insert this code in the header section between &#x3C;body&#x3E;&#x3C;/body&#x3E; tags in your page</p>
                  </div>
                  <div className="d-flex align-items-center">
                    <div className="embed-user-icon ms-2" onClick={() => handleCopy("body")} style={{ cursor: "pointer" }}><BiSolidCopy /></div>
                  </div>
                </div>
                <div className="code-block">
                  <p>
                    <textarea
                      value={bodyCode}
                      id="checkoutCode"
                      readOnly
                      rows={1}
                      style={{ background: "transparent", border: "none", height: "100%", width: "100%", padding: 10 }}
                    />
                  </p>
                </div>
              </div>

            </div>
          </div>
        </div>

        <Footer />

        {/* <Modal className="VideoModal sm-modal" show={show2} onHide={handleClose2} centered>
        <Modal.Body>
          <span className="modalClose abs" onClick={handleClose2}><AiOutlineClose /></span>
          <div className="">
            <div className="text-center pt-4">
              <div className="modalIcon"><BsCardImage /></div>

              <div className="confirmation-cont">
                <h5 className="pt-4">Are you sure to delete this “Johnson Doe - Embed AI Site” Embed ?</h5>
                <p>You can’t Restore the deleted Embed data</p>
              </div>

              <div className="btnGroup mt-4 mb-4">
                <button className="theme-btn bdr" onClick={handleClose2}><span>Cancel</span></button>
                <button className="theme-btn"><span><BiTrash /> Delete Embed</span></button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal> */}

      </>
  )
}

export default EmbedCode;